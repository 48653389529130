
$(document).on('turbolinks:load', function () {
  "use strict";
  /*=======================================
  sticky-header.js
  ======================================= */

  $(window).width() >= "768" && $(".navigation").sticky({ topSpacing: 0 })

  /*remove preload slider */
  $(".slides__preload_wrapper").fadeOut(1500);

  /* =======================================
  single Page Nav
  =======================================*/
  // The actual plugin
  $('.single-page-nav').singlePageNav({
    offset: $('.single-page-nav').outerHeight(),
    filter: ':not(.external)',
    updateHash: true,
  });

  /*=======================================
  Scroll Top
  =======================================*/
  $(".scrollup").on('click', function () {
    $('html,body').animate({
      'scrollTop': '0'
    }, 600);
    return false;
  });

  /* =======================================
    For Menu
  =======================================*/
  $("#navigation").menumaker({
    title: "",
    format: "multitoggle"
  });

  $('.file-upload').on("change", function () {
    var i = $(this).prev('label').clone();
    var file = $(this)[0].files[0].name;
    $(this).prev('i').text(file);
  });
});